/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, Info ,ContactMail} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { changeLang, getLang } from "../../slices/languageSlice";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lang = useSelector(getLang);
  
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="PRODUCTS"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/dev-lifecycle-page" className={classes.dropdownLink}>
              DCS DEVELOPMENT LIFECYCLE
            </Link>,
           <Link to="/wms-page" className={classes.dropdownLink}>
              DCS WAREHOUSE MANAGEMENT
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/about-us"
          color="transparent"          
          className={classes.navLink}        >
          <Info className={classes.icons} /> ABOUT
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/contract-us"
          color="transparent"          
          className={classes.navLink}        >
          <ContactMail className={classes.icons} /> CONTRACT US
        </Link>
      </ListItem>       
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/DCSolution-108763908392278/?ref=page_internal"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>  
    </List>
  );
}
