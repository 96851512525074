/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.center}>
          Copyright &copy; {1900 + new Date().getYear()}
          {" "}
          Development & Consultant Solution co., Ltd
          {" "}
          All Right Reserved
        </div>
        <div className={classes.center}>
          บริษัท ดีเวลอปเม้นท์ แอนด์ คอนซัลท์แทนท์ โซลูชั่น จำกัด
          {" "}
        </div>
        <div className={classes.center}>
          ที่อยู่
          {":"}
          32 ซอยนนทบุรี 10 ต.บางกระสอ อ.เมืองนนทบุรี จ.นนทบุรี
          {" "}
        </div>
        <div className={classes.center}>
          เลขทะเบียนนิติบุคคล
          {":"}
          0125565003228
          {" "}
        </div>
        <div className={classes.center}>
          <a href = "tel:+66866255288" ><img src={require("assets/img/phone.jpg").default} className={classes.iconDownSize} />+6686-625-5288</a>  | <img src={require("assets/img/email.jpg").default} className={classes.iconDownSize} /> nawawatsit@dcsolution.co.th
        </div>
      </div>
    </footer >
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
