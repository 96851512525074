import { title } from "assets/jss/material-kit-react.js";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  description: {
    color: "#000000",
  },
  description2: {
    color: "#ff9900",
  },
  imageDetail: {
    width: "170px",
    height: "55px",
    border: "solid 2px orange",
    borderRadius: "25px",
    margin: "10px",
  },
};

export default productStyle;
