import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import HomePage from "views/HomePage/HomePage.js";
import DevelopmentLifeCyclePage from "views/DevelopLifeCyclePage/DevelopmentLifeCyclePage.js";
import WarehouseManagementPage from "views/WarehouseManagementPage/WarehouseMangementpage.js";
import AboutUsPage from "views/AboutUsPage/AboutUspage.js";
import ContractUsPage from "views/ContractUsPage/ContractUsPage";

var hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/component-page" component={Components} />
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/profile-page" component={ProfilePage} />
        <Route
          path="/dev-lifecycle-page"
          component={DevelopmentLifeCyclePage}
        />
        <Route path="/wms-page" component={WarehouseManagementPage} />
        <Route path="/login-page" component={LoginPage} />
        <Route path="/about-us" component={AboutUsPage}></Route>
        <Route path="/contract-us" component={ContractUsPage}></Route>
        <Route path="/" component={HomePage} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
