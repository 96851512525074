import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Chat from "@material-ui/icons/Chat";
//import VerifiedUser from "@material-ui/icons/VerifiedUser";
//import AttachMoney from "@material-ui/icons/AttachMoney";
//import Create from "@material-ui/icons/Create";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>ทำไมต้องเรา?</h2>
          <h5 className={classes.description2}>
            &ldquo; คุณกำลังมองหามองหา IT ให้กับธุรกิจของ คุณใช่หรือไม่ ?
            &rdquo;
          </h5>
          <h4 className={classes.description2}>เราช่วยคุณได้ </h4>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h5 className={classes.description}>
            DCSOLUTION คือ บริษัทพัฒนาซอฟต์แวร์ ที่มีประสบการณ์มากว่า 10 ปี
            ด้วยแนวคิดที่จะพัฒนาซอฟต์แวร์ให้เหมาะสมกับธุรกิจที่หลากหลายประเภท
            โดยมุ่งเน้นและพัฒนาตามความต้องการของธุรกิจคุณ
            ควบคู่ไปกับการใช้เทคโนโลยีและนวัตกรรมที่มีเป้าหมายให้ซอฟต์แวร์นั้น
            ตอบสนองต่อผู้ใช้งานในด้านต่างๆได้อย่างมีประสิทธิภาพ
            ด้วยความเข้าใจในธุรกิจและกระบวนการทำงาน โดยเฉพาะอย่างยิ่งระบบ
            Warehouse Management, Inventory Management, Transportation
            Management รวมไปถึงระบบ Supply chain อื่นๆ
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
