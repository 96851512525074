import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import WbIncandescent from "@material-ui/icons/WbIncandescent";
import Build from "@material-ui/icons/Build";
import Public from "@material-ui/icons/Public";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/contents/dcs-dev-lc.jpg";

import dk1 from "assets/img/contents/dk-1.jpg";

import work1 from "assets/img/contents/system-imp-1.jpg";
import work2 from "assets/img/contents/system-imp-2.jpg";
import cooling1 from "assets/img/contents/cooling-1.jpg";

import Card from "components/Card/Card";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function DevelopmentLifeCyclePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="DCS Solution"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>DCS DEVELOPMENT LIFECYCLE</h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                DCSจะใช้รูปแบบการออกแบบ(Design)การพัฒนา(Development)รวมถึงการขึ้นระบบ(Implement)ตามมาตรฐานสากล
                เพื่อตอบสนองให้ตรงความต้องการของลูกค้า{" "}
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "D-Design Thinking",
                      tabIcon: WbIncandescent,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <img
                              alt="..."
                              src={dk1}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <Card>
                              Design Thinking
                              คือการกระบวนทำความเข้าใจปัญหาหรือความต้องของลูกค้า
                              ที่ทาง DCS นำมาใช้เพื่อเข้าใจลูกค้าอย่างแท้จริง!!
                            </Card>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "C-Cooling Development",
                      tabIcon: Build,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <img
                              alt="..."
                              src={cooling1}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "S-System Implement Anywhere",
                      tabIcon: Public,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={6}>
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <h6>Cloud Technology</h6>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <h6>On Premise Technology</h6>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
