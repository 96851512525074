import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
//import Chat from "@material-ui/icons/Chat";
//import VerifiedUser from "@material-ui/icons/VerifiedUser";
//import AttachMoney from "@material-ui/icons/AttachMoney";
//import Create from "@material-ui/icons/Create";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import designImage from "assets/img/design.jpg";
import dcsLogoImage from "assets/img/dcs-log-no-bg.png";
import Card from "components/Card/Card";
//import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/designStyle.js";

const useStyles = makeStyles(styles);

export default function DesignSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card carousel>
            <img src={designImage} alt="First slide" className="slick-image" />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <img
            src={dcsLogoImage}
            alt="First slide"
            className={classes.imageDetail}
          />
          <h3 className={classes.description}>
            ออกแบบและพัฒนาระบบตามมาฐานสากล
          </h3>
          <h2 className={classes.description2}>
            เพื่อตอบสองความต้องการที่แท้จริงของลูกค้า!
          </h2>
          <h2 className={classes.description2}>
            ระบบที่มีความหยืดยุ่นและมั่นคง
          </h2>
        </GridItem>
      </GridContainer>
    </div>
  );
}
