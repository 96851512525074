import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { PhoneInTalk } from "@material-ui/icons";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function AddressCompanySection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>ที่อยู่</h2>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                32 ซ. นนทบุรี10 ถ. นนทบุรี1 ต. บางกระสอ อ. เมือง จ. นนทบุรี
                11000
                <br></br>
                <a href="tel:086-625-5288">
                  <PhoneInTalk className={classes.icons} />
                </a>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
