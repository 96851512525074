/* eslint-disable prettier/prettier */
import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js';
import axios from 'axios';

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  const sendMail = async (e) => {
    e.preventDefault();
    const form = e.target;
    const name = form.name.value;
    const email = form.email.value;
    const message = form.message.value;
    const to = 'DCSolution.Company@gmail.com';
    const subject = `ข้อความจากลูกค้า ${name}`;
    const body = `<p>${message}</p><br/><p>จากอีเมล ${email}</p>`;

    const result = await axios.post(
      'https://asia-east2-dcs-project-e7741.cloudfunctions.net/app/sendmail',
      {
        to,
        subject,
        body,
      }
    );

    result.data.statusCode === '0000'
      ? alert('email sent !!')
      : alert('email not sent');

    form.reset();
  };
  return (
    <div className={classes.section}>
      <GridContainer justify='center'>
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>ติดต่อเรา</h2>
          <form onSubmit={(e) => sendMail(e)}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText='ชื่อ นามสกุล'
                  id='name'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText='เบอร์โทร'
                  id='phone'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText='อีเมลล์'
                  id='email'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <CustomInput
                labelText='ข้อความ'
                id='message'
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button type='submit' color='primary'>
                  ส่งข้อความ
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
