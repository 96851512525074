import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "language",
  initialState: {
    lang: "TH",
  },
  reducers: {
    changeLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { changeLang } = slice.actions;

export const getLang = (state) => {
  return state.language.lang;
};

export default slice.reducer;
