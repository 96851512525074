import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MapPicker from "react-google-map-picker";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);
const DefaultLocation = { lat: 13.8648258, lng: 100.4853885 };
const DefaultZoom = 10;

export default function CompanyMap() {
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  const classes = useStyles();
  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <GridContainer>
            <button onClick={handleResetLocation}>Reset Location</button>
            <MapPicker
              defaultLocation={defaultLocation}
              zoom={zoom}
              onChangeLocation={handleChangeLocation}
              onChangeZoom={handleChangeZoom}
              apiKey="AIzaSyAkBhTU6Tc8FNdu64ZRG4rPm2bin7H7OOI"
            />
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
