import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
//import logoMain from "assets/img/dcs-logo-main.jpg";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import FullStackSection from "./Sections/FullStackSection.js";
import DesignSection from "./Sections/DesignSection.js";
import ProtectionSection from "./Sections/ProtectionSection.js";
import PresentMeSection from "./Sections/PresentMeSection.js";
import Typewriter from "typewriter-effect";
//import TeamSection from "./Sections/TeamSection.js";
//import WorkSection from "./Sections/WorkSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function HomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.typewriter}>
                <h2>
                  <Typewriter
                    options={{
                      autoStart: true,
                      loop: true,
                    }}
                    cursorClassName={classes.typewriter}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString(`<span>รับออกแบบและพัฒนาระบบ</span>`)
                        .pauseFor(300)
                        .typeString(
                          ` <strong><span style="color: orange;">ครบวงจร!</span></strong>`
                        )
                        .pauseFor(300)
                        .start()
                        .pauseFor(30000);
                    }}
                  />
                </h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <DesignSection />
          <FullStackSection />
          <ProtectionSection />
          <PresentMeSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
